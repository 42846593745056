@import '../../../styles/vars.scss';
@import '../../../styles/scss/mixins/fluid-font-size.scss';
$mediaMaxWidth: 86.25rem + ($padd * 2);

.wrapper {
  position: relative;
  height: 37.5rem;

  @media (max-width: $w992) and (min-width: $w768) {
    height: 28.125rem;
  }
}

.img {
  height: 100%;
}

.picture {
  position: relative;
  width: 100%;
  height: 100%;

  @media (max-width: $tabletMaxWidth) {
    display: none;
  }

  &_mobile {
    display: none;
    position: relative;
    width: 100%;
    height: 100%;

    @media (max-width: $tabletMaxWidth) {
      display: block;
    }
  }
}

.slider {
  height: 100%;
}
.slide {
  position: relative;
  overflow: hidden;

  img {
    transition: transform 2s ease;
  }
}

.active {
  .slide {
    &_inner {
      right: 0;
    }
  }
  img {
    transform: scale(1.05);

    @media (max-width: $w576) {
      transform: none;
    }
  }
}

.text-align {
  &-right {
    text-align: right;
    .title,
    .description,
    .button {
      margin-left: auto;
    }
  }

  &-center {
    text-align: center;

    .title,
    .description,
    .button {
      margin-left: auto;
      margin-right: auto;
    }
  }

  &-none {
    display: none;
  }
}

.text-color {
  &-light {
    color: $white;
  }
}

.textOuter {
  position: absolute;
  bottom: 4.0625rem;
  left: 0;
  right: 0;
  top: $head-size;
  z-index: 1;
  display: flex;
  align-items: center;

  @media (max-width: $tabletMaxWidth) {
    bottom: auto;
    top: 4.875rem;
  }
}

.text {
  width: 100%;
  padding: 0 calc((100vw - 86.25rem) / 2);

  @media (max-width: $mediaMaxWidth) {
    padding: 0 $padd;
  }

  @media (max-width: $w1200) {
    padding: 0 $padd-medium;
  }
  @media (max-width: $tabletMaxWidth) {
    max-width: none;
    text-align: center;
  }

  @media (max-width: $tabletMaxWidth) {
    padding: 0 $padd-small;
  }
}

.title {
  font-weight: 400;
  line-height: 1.1;
  @include fluid-font-size($f30, $f46, $f60);
  margin: 0 0 1rem;
  max-width: 40%;

  @media (max-width: $w1200) {
    max-width: 50%;
  }

  @media (max-width: $tabletMaxWidth) {
    max-width: none;
  }
}

.description {
  line-height: 1.5;
  max-width: 25rem;
  margin: 0;

  @include fluid-font-size($f18, $f20, $f20);

  @media (max-width: $tabletMaxWidth) {
    max-width: none;
  }
}

.button {
  margin: 1.5rem 0 0;

  @media (max-width: $tabletMaxWidth) {
    margin: 1rem auto 0;
  }

  &-black {
    color: $primary;
    svg ellipse {
      stroke: $primary;
    }

    &:hover {
      color: $brown;
      svg ellipse {
        stroke: $brown;
      }
    }
  }
  &-white {
    color: $white;

    svg ellipse {
      stroke: $white;
    }

    &:hover {
      color: $brown;

      svg ellipse {
        stroke: $brown;
      }
    }
  }
  &-brown {
    color: $brown;
    svg ellipse {
      stroke: $brown;
    }

    &:hover {
      color: $primary;
      svg ellipse {
        stroke: $brown;
      }
    }
  }
}

.btn {
  background: $white;
  cursor: pointer;
  border: 1px solid $light;
  border-radius: 50%;
  width: 6.25rem;
  height: 6.25rem;
  position: absolute;
  bottom: -3.125rem;
  z-index: 1;
  opacity: 1;
  transition: opacity $ease-in;

  @media (max-width: $tabletMaxWidth) {
    width: 4.25rem;
    height: 4.25rem;
    bottom: -2.125rem;
  }

  svg {
    width: 1.5rem;
    height: 1.5rem;

    path {
      fill: $brown;
    }
  }

  &_next {
    right: calc((100vw - 86.25rem) / 2);

    svg {
      transform: rotate(180deg);
    }

    @media (max-width: $mediaMaxWidth) {
      right: $padd;
    }

    @media (max-width: $w1200) {
      right: $padd-medium;
    }

    @media (max-width: $w768) {
      right: $padd-small;
    }
  }
}

.pagination {
  display: flex;
  justify-content: center;
  width: 100%;
  position: absolute;
  bottom: 1.875rem;
  z-index: 1;
  margin: 0 auto;

  @media (max-width: $tabletMaxWidth) {
    bottom: 1.25rem;
  }
}

.dot {
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 50%;
  border: 1px solid $white;
  background: transparent;
  cursor: pointer;
  transition: background $ease-in;

  &:not(:last-child) {
    margin-right: 0.625rem;
  }

  &_active {
    background: $white;
  }
}

@import '../../styles/vars.scss';

.more {
  margin-top: 7.5rem;

  @media (max-width: $w992) {
    margin-top: 3.75rem;
  }
}

.plates {
  margin-top: 7.5rem;
  @media (max-width: $w992) {
    padding: 0 $padd-medium;
  }
  @media (max-width: $w768) {
    padding: 0;
    margin: 0;
  }

  & > div {
    display: flex;

    &:nth-child(even) {
      flex-direction: row-reverse;
    }

    @media (max-width: $w768) {
      display: block;
    }

    & > figure,
    & > div {
      margin: 0;
      width: 50%;

      @media (max-width: $w768) {
        width: 100%;
      }
    }

    & .image {
      min-height: 30.5rem;
      position: relative;

      @media (max-width: $w768) {
        display: none;
      }
    }

    & .mobile_image {
      display: none;

      @media (max-width: $w768) {
        margin-top: 3.75rem;
        display: block;
      }
    }

    & > div {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 0.625rem 2.8rem;

      @media (max-width: $w992) {
        padding: 0.625rem 0.3125rem;
      }

      @media (max-width: $w768) {
        margin-top: 3.75rem;
      }

      h3 {
        font-weight: normal;
        font-size: $f18;
        line-height: 1.5;
        color: $brown;
        margin: 0.875rem 0 0;
      }

      p {
        font-size: $f36;
        line-height: 1.3;
        text-align: center;
        margin: 0.875rem 0 0;

        @media (max-width: $w1200) {
          font-size: $f30;
        }
        @media (max-width: $w992) {
          font-size: $f24;
        }
      }

      .button {
        margin-top: 30px;
      }
    }
  }
}

.video_wrapper {
  margin-top: 7.5rem;

  @media (max-width: $w992) {
    margin-top: 3rem;
  }
  @media (max-width: $tabletMaxWidth) {
    margin-top: 0;
  }
}

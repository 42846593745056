@import '../../../styles/vars.scss';
@import '../../../styles/scss/mixins/fluid-font-size.scss';

.wrapper {
  position: relative;
  display: block;

  &:hover {
    .heading {
      color: $brown;
    }

    .category {
      color: $primary;
    }
  }

  @media (max-width: $w768) {
    margin-bottom: 2.5rem;
  }

  &.big {
    max-width: 68%;
    width: 100%;
    margin-right: 2.1875rem;

    .cover {
      & > span {
        width: 100% !important;
        height: 100% !important;
      }
    }

    @media (max-width: $w768) {
      max-width: 100%;
      margin-right: 0;
    }
  }

  &.medium {
    max-width: 25rem;

    .cover {
      height: 28.125rem;

      & > span {
        width: 100% !important;
        height: 100% !important;
      }

      @media (max-width: $w992) {
        height: auto;
      }
    }

    @media (max-width: $w992) {
      max-width: 45%;
    }

    @media (max-width: $w768) {
      max-width: 100%;
    }
  }

  &.small {
    @media (max-width: $w768) {
      margin-bottom: 2.5rem;
    }

    .cover {
      height: auto;

      &.high {
        height: 450px;

        & > span {
          height: 100% !important;
        }

        @media (max-width: $w992) {
          height: auto;
        }
      }
      &.square {
        & > span > span {
          padding-top: 100% !important;

          @media (max-width: $w992) {
            padding-top: 50.2% !important;
          }
        }

        img {
          position: absolute;
          top: 0;

          @media (max-width: $w992) {
            position: static;
          }
        }
      }

      img {
        height: 100%;
      }
    }
  }
}

.cover {
  margin: 0 0 0.75rem;

  img {
    width: 100%;
    height: 100%;
  }
}

.text {
  @media (max-width: $w992) {
    padding: 0 $padd-medium;
  }
  @media (max-width: $w992) {
    padding: 0 $padd-small;
  }
}

.category {
  font-size: $f18;
  line-height: 1.2;
  letter-spacing: 0.1em;
  color: $brown;
  text-transform: lowercase;
  margin: 0 0 0.5rem;
  transition: all $ease-in;

  @media (max-width: $w768) {
    margin-bottom: 0.3125rem;
  }
}

.heading {
  @include fluid-font-size($f28, $f30, $f36);
  line-height: 1.1;
  color: $primary;
  transition: color $ease-in;
  margin: 0 0 0.625rem 0;

  @media (max-width: $w768) {
    line-height: 1.2;
    margin-bottom: 0.625rem;
  }
}

.announcement {
  font-size: $f18;
  line-height: 1.5;
  color: $primary;

  @media (max-width: $w768) {
    font-size: $f18;
  }
}

.new {
  position: absolute;
  top: -2.1875rem;
  left: -2.5rem;
  will-change: transform;
  animation: rotate 7s linear infinite;

  @media (max-width: $w768) {
    display: none;
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

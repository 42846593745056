@import '../../../../styles/vars.scss';

.rootContainer {
  position: fixed;
  top: 5.625rem;
  right: 1.25rem;
  z-index: 5;
  pointer-events: none;
}

.container {
  width: fit-content;
  margin-bottom: 1rem;
  padding: 0.75rem;
  overflow: hidden;
  pointer-events: all;
  transition: transform 0.3s ease-out;
  background-color: $black-1f;

  &.slideOut {
    transform: translate3d(150%, 0, 0);
  }

  &.slideIn {
    animation-name: slideIn;
    animation-duration: 0.3s;
    animation-timing-function: ease-in-out;
  }
}

.close {
  display: none;
}

@media (max-width: $w992) {
  .rootContainer {
    top: 0.5rem;
    right: 0;
    width: calc(100% - 2.5rem);
    margin: 0 1.25rem;
  }

  .container {
    position: relative;
    width: 100%;
    padding: 0.75rem 1rem;
  }
  .close {
    position: absolute;
    top: 0.875rem;
    right: 1rem;
    display: block;
    width: 1rem;
    height: 1rem;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border: none;
    cursor: pointer;
    color: $white;

    svg {
      width: 100%;
      height: 100%;
      color: $white;
    }
  }
}

@keyframes slideIn {
  from {
    transform: translate3d(150%, 0, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

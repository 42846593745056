@import '../../../../styles/vars.scss';

.wrapper {
  margin: 0;
  position: relative;
}

.slide {
  cursor: pointer;
}

.pagination {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  position: absolute;
  bottom: 1.875rem;
  z-index: 1;
  margin: 0 auto;

  @media (max-width: $w992) {
    bottom: 1.25rem;
  }
}

.dot {
  width: 0.375rem;
  height: 0.375rem;
  border-radius: 50%;
  background: $black20t;
  cursor: pointer;
  transition: background $ease-in;
  margin-top: 0.625rem;

  &:not(:last-child) {
    margin-right: 0.625rem;
  }

  &_active {
    background: $primary;
  }
}

@import '../../../styles/vars.scss';
@import '../../../styles/scss/mixins/grid.scss';
@import '../../../styles/scss/mixins/fluid-font-size.scss';

.wrapper {
  margin-top: 7.5rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto 38.625rem auto;
  grid-gap: 0 5rem;

  @media (max-width: $w992) {
    grid-template-columns: 100%;
    grid-template-rows: auto;
    grid-gap: 0;
    margin-top: 3.75rem;
    padding: 0 $padd-medium;
  }

  @media (max-width: $w768) {
    flex-direction: column;
    padding: 0;
  }

  & > div {
    @media (max-width: $w992) {
      order: 0;
    }
  }
}

.list {
  $template-map: (
    dflt: (
      cols: 2,
      mh: 1.875rem,
      mv: 2.5rem,
    ),
    1400px: (
      cols: 2,
      mh: 1.875rem,
      mv: 2.5rem,
    ),
    $w1200: (
      cols: 2,
      mh: 1.875rem,
      mv: 2.5rem,
    ),
    $w992: (
      cols: 2,
      mh: 1.875rem,
      mv: 2.5rem,
    ),
    $w768: (
      cols: 2,
      mh: 1.875rem,
      mv: 2.5rem,
    ),
    576px: (
      cols: 1,
      mh: 1.875rem,
      mv: 1.875rem,
    ),
    400px: (
      cols: 1,
      mh: 1.25rem,
      mv: 1.25rem,
    ),
  );
  @include grid($template-map);
  margin: 0;
  padding: 0;
  grid-row: 2/4;

  @media (max-width: $w992) {
    order: 3;
    grid-row: auto;
    margin-top: 2.5rem;
  }

  @media (max-width: $w768) {
    padding: 0 $padd-small;
  }
}

.title {
  font-family: $apoc;
  font-weight: normal;
  @include fluid-font-size($f64, $f70, $f110);
  line-height: 0.95;
  margin: 0;

  @media (max-width: $w768) {
    padding: 0 $padd-small;
  }
}

.description {
  font-size: $f20;
  margin-top: 1.25rem;

  @media (max-width: $w992) {
    font-size: $f18;
  }

  @media (max-width: $w768) {
    padding: 0 $padd-small;
  }
}

.cover {
  margin: 0;
  position: relative;
  grid-row: 1/3;
  grid-column: 2/3;

  & > span {
    height: 100% !important;
  }

  @media (max-width: $w992) {
    order: 1;
    grid-row: auto;
    grid-column: auto;
  }
}

.advantages_list {
  display: flex;
  justify-content: space-between;
  margin: 2.5rem 0 0;
  padding: 0;
  list-style: none;

  @media (max-width: $w992) {
    order: 2;
    padding: 0 $padd-small;
  }
  @media (max-width: $w768) {
    padding: 0 $padd-small;
  }

  li {
    width: calc(50% - 1.5rem);
    display: flex;
    align-items: center;
    color: $secondary;
    font-size: $f18;

    @media (max-width: $w992) {
      width: calc(50% - 0.625rem);
      flex-direction: column;
      text-align: center;
      font-size: $f16;
    }

    & > span {
      width: 3.4375rem !important;
      height: 3.4375rem !important;
      margin-right: 1rem !important;
      flex-shrink: 0;

      @media (max-width: $w992) {
        margin: 0 0 0.625rem 0 !important;
      }
    }

    p {
      margin: 0;
    }
  }
}

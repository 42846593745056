@import '../../../styles/vars.scss';

.wrapper {
  display: flex;
  justify-content: space-between;
  max-width: 86.25rem;
  margin: 0 auto;

  @media (max-width: $w992) {
    padding-top: 2.5rem;
    flex-direction: column-reverse;
  }
}

.image {
  width: 100%;
  max-width: 43.75rem;
  margin: 0;
  margin-right: 1.875rem;

  @media (max-width: $w992) {
    max-width: none;
    margin-bottom: 0;
  }
}

.more {
  max-width: 30rem;

  @media (max-width: $w992) {
    max-width: 100%;
    margin-bottom: $marg24;
  }
}

.sparcle {
  margin-bottom: 1.25rem;
  text-align: center;

  @media (max-width: $w992) {
    display: none;
  }
}

.heading {
  font-size: $f36;
  line-height: 1.3;
  text-align: center;
  color: $primary;
  margin: 0 0 2.1875rem;

  @media (max-width: $w992) {
    font-size: $f28;
    line-height: 1.2;
    margin-bottom: $marg24;
  }
}

.socials {
  display: flex;
  justify-content: center;
  max-width: 11.875rem;
  margin: 0 auto 2.8125rem;
  list-style: none;
  padding: 0;

  @media (max-width: $w992) {
    margin-bottom: 1.25rem;
  }

  li:not(:last-child) {
    margin-right: 1rem;
  }

  a {
    width: 3.3125rem;
    height: 3.3125rem;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all $ease-in;
    position: relative;

    @media (max-width: $w576) {
      width: 2.5rem;
      height: 2.5rem;
    }

    &:not(:last-child) {
      margin-right: 0.8125rem;
    }

    &:hover {
      svg path {
        fill: $brown;
        transition: fill 0.2s linear;
      }
    }
  }
}

.border {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;

  :global(.border-brown) {
    stroke-dasharray: 500;
    stroke-dashoffset: 500;
  }

  :global(.border-black) {
    stroke-opacity: 1;
  }

  &:hover {
    :global(.border-brown) {
      transition: stroke-dashoffset 1s linear;
      stroke-dashoffset: 0;
    }
    :global(.border-black) {
      stroke-opacity: 0;
    }
  }
}

.button {
  margin: 0 auto;
  text-transform: none;

  @media (max-width: $w992) {
    font-size: $f20;
  }
}

@use 'sass:math';

@mixin grid-item($rules) {
  $cols: if(map_has_key($rules, 'cols'), map_get($rules, 'cols'), 1);
  $margin-right: if(map_has_key($rules, 'mh'), map_get($rules, 'mh'), 0px);
  $spacing: if($cols > 1, $margin-right * math.div(($cols - 1), $cols), 0px);

  @if ($spacing > 0) {
    $width-base: math.div(100%, $cols);
    width: calc(#{$width-base} - #{$spacing});
  } @else {
    width: calc(100% / #{$cols});
  }

  &:nth-child(n) {
    margin-right: $margin-right;
    margin-top: if(map_has_key($rules, 'mv'), map_get($rules, 'mv'), 0);
  }
  &:nth-child(#{$cols}n) {
    margin-right: 0;
  }
  &:nth-child(-n + #{$cols}) {
    margin-top: 0;
  }

  @if (map_has_key($rules, 'ih')) {
    height: map_get($rules, 'ih');
  }
}

/**
приимер вызова:

$tpl-map: (
    dflt: (cols:1, mv:1.25rem), // on width > 1600
    1600px: (cols:2, mh:0.625rem, mv: 0.625rem), // on width 1200 ... 1600
    75rem: (cols:2, mh:1.25rem, mv:1.25rem),
    62rem: (cols:3, mh:1.25rem, mv:1.875rem),
    768: (cols:4, mh:1.25rem, mv:2.5rem),
    576: (cols:4, mh:1.25rem, mv:2.5rem), // on width >< 576
);
@include grid($tpl-map);

*/

@mixin grid($grid-map, $child: '*') {
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
  width: 100%;

  > #{$child} {
    @each $breakpoint, $rules in $grid-map {
      @if ($breakpoint == dflt) {
        @include grid-item($rules);
      } @else {
        @media (max-width: $breakpoint) {
          @include grid-item($rules);
        }
      }
    }
  }
}

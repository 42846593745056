@import '../../../styles/vars.scss';
@import '../../../styles/scss/mixins/fluid-font-size.scss';

.wrapper {
  margin-top: 5rem;

  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto 18.75rem auto;
  grid-gap: 0 5rem;

  @media (max-width: $w992) {
    grid-template-rows: auto 4.375rem auto;
    grid-gap: 0 2rem;
    padding: 0 $padd-medium;
  }

  @media (max-width: $w768) {
    grid-template-columns: 100%;
    grid-template-rows: auto;
    padding: 0;
  }
}

.top {
  order: 0;
  @media (max-width: $w768) {
    padding: 0 $padd-small;
  }
}

.title {
  font-weight: normal;
  line-height: 0.95;
  margin: 0;
  font-family: $apoc;
  @include fluid-font-size($f64, $f70, $f110);
}

.advantage_list {
  list-style: none;
  padding: 0;
  margin: 0 0 3.75rem;
  display: flex;
  flex-wrap: wrap;

  li {
    margin: 2.8125rem 0 0;
    width: 50%;
    display: flex;
    align-items: center;
    font-size: $f18;
    color: $secondary;

    @media (max-width: $w1200) {
      font-size: $f16;
    }

    @media (max-width: $w992) {
      margin: 1.875rem 0 0;
      flex-direction: column;
      text-align: center;
      font-size: $f14;
    }

    & > span {
      width: 3.4375rem !important;
      height: 3.4375rem !important;
      margin-right: 1rem !important;
      flex-shrink: 0;

      @media (max-width: $w992) {
        margin: 0 0 10px 0 !important;
      }
    }

    p {
      margin: 0;
    }
  }
}

.catalog_link {
  color: $white;
  grid-column: 1/2;
  grid-row: 2/4;

  @media (max-width: $w768) {
    order: 3;
    grid-column: auto;
    grid-row: auto;
    margin-top: 3.75rem;
  }
  &:hover {
    color: $brown;

    & figcaption::before {
      background: $brown;
    }
  }
  figure {
    margin: 0;
    height: 100%;
    position: relative;

    & > span {
      height: 100% !important;
    }

    figcaption {
      position: absolute;
      display: block;
      bottom: 2.5rem;
      left: 2.25rem;
      text-transform: lowercase;

      @media (max-width: $w768) {
        left: $padd-small;
      }

      &::before {
        content: '';
        position: absolute;
        top: -1.6875rem;
        left: 0;
        width: 6rem;
        height: 1px;
        background: $white;
        transition: background $ease-in;
      }
    }
  }
}

.cover {
  margin: 0;
  height: 100%;
  position: relative;
  grid-column: 2/3;
  grid-row: 1/3;

  & > span {
    height: 100% !important;
  }

  @media (max-width: $w768) {
    order: 1;
    grid-column: auto;
    grid-row: auto;
  }
}

.quote {
  margin: 0;
  @include fluid-font-size($f22, $f28, $f31);

  @media (max-width: $w768) {
    padding: 0 $padd-small;
  }

  &_description {
    margin: 1.875rem 0 0;
    display: flex;
    align-items: center;
    font-style: normal;

    @media (max-width: $w768) {
      padding: 0 $padd-small;
    }

    figure {
      width: 3.75rem;
      height: 3.75rem;
      margin: 0 1.25rem 0 0;
      flex-shrink: 0;
      position: relative;

      img {
        border-radius: 50%;
      }
    }

    p {
      font-size: $f18;
      color: $secondary;
      margin: 0;

      &:first-child {
        font-size: $f20;
        color: $primary;
      }
    }
  }
}

.quote_block {
  grid-column: 2/3;
  margin-top: 2.5rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  @media (max-width: $w768) {
    order: 2;
    grid-column: auto;
  }
}

@import '../../../styles/vars.scss';
@import '../../../styles/scss/mixins/fluid-font-size.scss';

.wrapper {
  position: relative;
}

.preview_wrapper {
  position: relative;
  height: 100%;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    background: $gray;
  }

  &.played {
    &::after {
      display: none;
    }
  }

  @media (max-width: $w992) {
    height: 100%;
  }

  & > span:first-child {
    display: block !important;
    height: 100% !important;
  }
}

.text {
  max-width: 28.125rem;
  color: $white;

  p {
    margin: 0.5rem 0;
  }
}

.title {
  line-height: 1.1;
  margin: 0;
  font-weight: normal;
  @include fluid-font-size($f30, $f43, $f60);
}

.controls {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  position: absolute;
  bottom: 4.0625rem;
  width: 100%;
  padding: 0 4.0625rem;
  z-index: 2;

  @media (max-width: $w992) {
    flex-direction: column;
    align-items: flex-start;
    padding: 0 $padd-medium;
    top: 1.5rem;
    bottom: 1.5rem;
  }
  @media (max-width: $w768) {
    padding: 0 $padd-small;
  }
}

.play {
  cursor: pointer;
  @media (max-width: $w992) {
    display: none;
  }

  &_mobile {
    display: none;

    @media (max-width: $w992) {
      display: block;
      flex-shrink: 0.5;
      max-width: 2.5rem;
    }
  }
}

.video {
  $self: &;
  width: 100%;

  &_wrapper {
    height: 100%;

    & :global(.plyr) {
      height: 100%;

      video {
        height: auto;

        @media (max-width: $w992) {
          height: 100%;
        }
      }
    }

    & :global(.plyr--video) {
      overflow: unset;
    }
    & :global(.plyr__controls) {
      padding: 0;
      bottom: -2px;
      opacity: 1 !important;
      transform: translateY(0) !important;
      background: none;
    }
    & :global(.plyr__progress) {
      width: 100%;
      left: 0;

      input {
        height: 2px;
        width: 100%;
        margin: 0;
        color: $brownLighter;
        background: none;

        &::-webkit-slider-thumb {
          display: none;
        }
        &::-moz-range-thumb {
          background: none;
          box-shadow: none;
        }
      }
    }
    & :global(.plyr__tooltip) {
      display: none;
    }
    & :global(.plyr__progress__buffer) {
      width: 100%;
    }

    @media (max-width: $w992) {
      height: 100%;

      & > div {
        height: 100%;
      }
    }
    &:hover {
      #{$self}_mute {
        opacity: 1;
      }
    }
  }

  // &_hidden {
  //   display: none;
  // }

  &_mute {
    position: absolute;
    top: 1.875rem;
    right: 1.875rem;
    z-index: 1;
    width: 3.4375rem;
    height: 3.4375rem;
    border: none;
    border-radius: 50%;
    background: $white;
    cursor: pointer;
    opacity: 0;
    transition: opacity $ease-in;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    &:focus {
      outline: none;
    }
  }
}

.poster {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 1;
  margin: 0;

  & > span {
    height: 100% !important;
    width: 100% !important;

    & > span {
      display: none !important;
    }
  }
}

@import '../../../styles/vars.scss';
@import '../../../styles/scss/mixins/fluid-font-size.scss';

.wrapper {
  margin-top: 120px;

  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto 36rem auto;
  grid-gap: 0 5rem;

  @media (max-width: $w992) {
    padding: 0 $padd-medium;
    grid-template-rows: auto 20rem auto;
    grid-gap: 0 2rem;
  }
  @media (max-width: $w768) {
    grid-template-columns: 100%;
    grid-template-rows: auto;
    padding: 0;
    grid-gap: 0;
  }

  & > div {
    margin-bottom: 1.875rem;

    @media (max-width: $w768) {
      order: 0;
    }
  }
}

.toggle_image_wrapper {
  position: relative;
  width: 100%;
  margin: 0;
  grid-row: 1/3;
  grid-column: 2/3;

  @media (max-width: $w768) {
    grid-row: auto;
    grid-column: auto;
    order: 1;
  }

  & > span {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
    transition: opacity $ease-in;

    @media (max-width: $w768) {
      grid-row: auto;
      grid-column: auto;
      order: 1;

      &:nth-child(2) {
        position: relative;
      }
    }

    & > span {
      height: 100% !important;
    }

    &.hidden {
      opacity: 0;
    }

    &.active {
      opacity: 1;
    }
  }
}

.toggleWrapper {
  display: flex;
  align-items: center;
  background: $white;
  border-radius: 6.25rem;
  min-width: 10.625rem;
  height: 4.8125rem;
  position: absolute;
  bottom: 0.625rem;
  left: 0.625rem;
  cursor: pointer;

  @media (max-width: $w992) {
    height: 2.5rem;
    min-width: 5rem;
  }

  .toggle_mini {
    display: block;
    width: 4.4375rem;
    height: 4.4375rem;
    object-fit: cover;
    border-radius: 50%;
    position: absolute;
    top: 3px;
    left: 3px;
    transition: $ease-in;
    overflow: hidden;

    @media (max-width: $w992) {
      width: 2.25rem;
      height: 2.25rem;
      top: 2px;
      left: 2px;
    }

    &.hidden {
      opacity: 0;
    }
    &.active {
      opacity: 1;
    }
  }

  padding-left: 5.75rem;
  margin: 0;
  font-size: $f36;

  @media (max-width: $w992) {
    font-size: $f18;
    padding-left: 3rem;
  }

  span {
    display: none;

    &.active {
      display: inline;
      line-height: 1;
      position: relative;
      top: -4px;

      @media (max-width: $w992) {
        position: static;
        line-height: inherit;
      }
    }
  }

  &.active {
    padding: 0 5.75rem 0 1.5rem;

    @media (max-width: $w992) {
      padding: 0 2.8125rem 0 1rem;
    }

    .toggle_mini {
      left: calc(100% - 4.625rem);

      @media (max-width: $w992) {
        left: calc(100% - 2.4375rem);
      }
    }
  }
}

.title {
  @include fluid-font-size($f64, $f70, $f110);
  font-family: $apoc;
  font-weight: normal;
  line-height: 0.95;
  margin: 0;

  @media (max-width: $w768) {
    padding: 0 $padd-small;
  }
}

.description {
  font-size: $f20;
  margin: 1.875rem 0 0;

  @media (max-width: $w768) {
    padding: 0 $padd-small;
  }
}

.catalog_link {
  color: $white;
  grid-row: 2/4;

  @media (max-width: $w768) {
    grid-row: auto;
    order: 3;
    margin-top: 1.875rem;
  }
  &:hover {
    color: $brown;

    figcaption::before {
      background: $brown;
    }
  }
  figure {
    margin: 0;
    height: 100%;
    position: relative;

    & > span {
      height: 100% !important;
    }

    @media (max-width: $w768) {
      padding: 0 $padd-small;
    }

    figcaption {
      position: absolute;
      display: block;
      bottom: 2.5rem;
      left: 2.25rem;

      &::before {
        content: '';
        position: absolute;
        top: -1.6875rem;
        left: 0;
        width: 6rem;
        height: 1px;
        background: $white;
        transition: background $ease-in;
      }
    }
  }
}

.advantages_list {
  padding: 0;
  margin: 2.5rem 0 0;
  list-style: none;
  display: flex;
  justify-content: space-between;
  grid-row: 3/4;

  @media (max-width: $w768) {
    padding: 0 $padd-small;
    grid-row: auto;
    order: 2;
  }

  li {
    width: calc(50% - 2.1875rem);
    display: flex;
    align-items: center;

    @media (max-width: $w1200) {
      width: calc(50% - 1.25rem);
    }

    @media (max-width: $w992) {
      flex-direction: column;
      text-align: center;
    }

    & > span {
      width: 3.4375rem !important;
      height: 3.4375rem !important;
      margin-right: 1rem !important;
      flex-shrink: 0;

      @media (max-width: $w992) {
        margin: 0 0 0.625rem 0 !important;
      }
    }

    p {
      margin: 0;
      color: $secondary;
    }
  }
}

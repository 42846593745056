@import '../../../../styles/vars.scss';

.container {
  display: flex;
  align-items: center;
  column-gap: 1.25rem;

  img {
    object-fit: cover;
  }
}

.content {
  max-width: 14.375rem;
}

.add {
  margin-bottom: 0.25rem;
  display: flex;
  align-items: center;
  color: $brownLight;
  font-size: $f14;

  svg {
    width: 1.25rem;
    height: 1.25rem;
    margin-right: 0.5rem;
  }

  &--mobile {
    display: none;
  }
}

.title {
  margin: 0 0 0.5rem 0;
  font-size: $f16;
  line-height: 1.2;
  color: $white;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.variant {
  display: flex;
  align-items: flex-start;
  column-gap: 0.5rem;
  font-size: $f14;
  color: $white60t;
}

.variantImage {
  border-radius: 50%;
}

@media (max-width: $w992) {
  .container {
    display: none;
  }

  .add--mobile {
    width: 100%;
    height: 1.25rem;
    margin: 0;
    display: flex;
    color: $white;

    svg {
      width: 1rem;
    }
  }
}

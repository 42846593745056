@import '../../../styles/vars.scss';
@import '../../../styles/scss/mixins/fluid-font-size.scss';

.wrapper {
  margin-top: 7.5rem;

  @media (max-width: $w992) {
    margin-top: 3.75rem;
  }
}

.top {
  display: flex;
  align-items: flex-end;
  margin-bottom: 3.125rem;

  @media (max-width: $w992) {
    flex-direction: column;
    align-items: center;
    margin-bottom: 1rem;
    padding: 0 $padd-medium;
  }
  @media (max-width: $w768) {
    padding: 0 $padd-small;
  }

  p {
    font-size: $f28;
    line-height: 1.1;
    margin: 0;

    @media (max-width: $w992) {
      font-size: $f22;
      color: $secondary;
    }
  }
}

.heading {
  font-weight: normal;
  line-height: 0.95;
  margin: 0 1.875rem 0 0;
  @include fluid-font-size($f64, $f70, $f110);

  @media (max-width: $w992) {
    margin: 0 0 1.25rem 0;
  }
}

.button {
  margin-left: auto;
  letter-spacing: 0.1em;

  @media (max-width: $w992) {
    display: none;
  }
}

.list {
  margin: 0;
  padding: 0;
  display: grid;
  grid-gap: 3.125rem;
  grid-template-columns: repeat(3, 1fr);

  @media (max-width: $w992) {
    grid-template-columns: repeat(2, 1fr);
    padding: 0 $padd-medium;
  }

  @media (max-width: $w768) {
    display: block;
    padding: 0;
  }
}

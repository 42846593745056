@import '../../../styles/vars.scss';
@import '../../../styles/scss/mixins/fluid-font-size.scss';

.wrapper {
  margin-top: 5.625rem;
}

.top {
  text-align: center;

  span {
    font-size: $f20;
    color: $secondary;

    @media (max-width: $w768) {
      font-size: $f18;
    }
  }
}

.heading {
  font-weight: normal;
  margin: 0 auto 1.25rem;
  max-width: 28.75rem;
  line-height: 1.1;
  @include fluid-font-size($f30, $f40, $f60);
}

.list {
  margin: 2.25rem 0 0;
  padding: 0;
  list-style: none;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 0 4.125rem;

  @media (max-width: $w992) {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2.125rem;
  }
  @media (max-width: $w768) {
    grid-template-columns: 100%;
    grid-gap: 2.125rem 0;
  }
}

.button {
  margin: 3.125rem auto 0;
}

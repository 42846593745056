@import '../../../styles/vars.scss';
@import '../../../styles/scss/mixins/fluid-font-size.scss';

.wrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 10rem;

  @media (max-width: $w992) {
    padding: 0 $padd-medium;
    margin-top: 6.25rem;
  }
  @media (max-width: $w768) {
    flex-direction: column;
    padding: 0;
    margin-top: 2.5rem;
  }

  & > div:first-child {
    max-width: 30rem;
    width: 100%;
    margin-right: 1.5rem;

    @media (max-width: $w768) {
      margin-right: 0;
      max-width: none;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  & > div:nth-child(2) {
    max-width: 50%;
    width: 100%;

    @media (max-width: $w768) {
      max-width: none;
    }
  }
}

.sliderWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.slider {
  @media (max-width: $w992) {
    margin-top: 1.5rem;
  }

  @media (max-width: $w768) {
    padding: 0 $padd-small;
  }

  .slide {
    background: $white;
    padding-bottom: 1.875rem;
  }

  blockquote {
    margin: 0;
    @include fluid-font-size($f24, $f28, $f36);
    line-height: 1.3;
  }

  cite {
    display: flex;
    align-items: center;
    font-style: normal;
    margin-top: 1.5rem;

    figure {
      margin: 0 0.625rem 0 0;
      width: 3.75rem;
      height: 3.75rem;
      flex-shrink: 0;

      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: cover;
      }
    }

    p {
      margin: 0;
      font-size: $f20;
    }

    span {
      font-size: $f14;
      color: $secondary;
    }
  }
}

.controlsWrapper {
  display: flex;
  align-items: center;
  position: relative;

  @media (max-width: $w768) {
    padding: 0 $padd-small;
  }
}

.next {
  margin-left: 1.25rem;
  background: $white;
  cursor: pointer;
  border: 1px solid $light;
  border-radius: 50%;
  width: 4.25rem;
  height: 4.25rem;
  position: absolute;
  right: 0;
  z-index: 1;

  @media (max-width: $w768) {
    right: $padd-small;
  }

  svg {
    width: 1.5rem;
    height: 1.5rem;
    transform: rotate(180deg);

    path {
      fill: $brown;
    }
  }
}

.pag {
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
  padding-right: 5.5rem;

  &_line {
    width: 6.25rem;
    height: 1px;
    background: $black20t;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      height: 3px;
      background: $brownLighter;
      bottom: 0;
      left: 0;
      width: 0%;
      z-index: 1;
    }
  }

  &.played .active::after {
    width: 100%;
    transition: width var(--transition-duration) linear;
  }
}

.title {
  font-weight: normal;
  font-family: $apoc;
  line-height: 1.1;
  @include fluid-font-size($f30, $f40, $f60);
  margin: 0;

  @media (max-width: $w768) {
    display: none;
  }
}

.description {
  margin: 1.25rem 0 0;
  font-size: $f20;

  @media (max-width: $w768) {
    display: none;
  }
}

.video {
  &_wrapper {
    position: relative;
    > div {
      height: 37.125rem;

      @media (max-width: $w768) {
        height: 37.5rem;
      }
    }

    & :global(.plyr__video-wrapper) {
      height: 100%;
    }

    div > video {
      object-fit: cover;

      @media (max-width: $w768) {
        height: 100%;
      }
    }
  }

  &_mute {
    position: absolute;
    top: 1.875rem;
    right: 1.875rem;
    z-index: 1;
    width: 3.4375rem;
    height: 3.4375rem;
    border: none;
    border-radius: 50%;
    background: $white;
    cursor: pointer;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

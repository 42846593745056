@use 'sass:math';
@function strip-unit($value) {
  @return math.div($value, ($value * 0 + 1));
}

@mixin fluid-font-size($fs1, $fs2, $fs3) {
  $w1: 23.4375rem;
  $w2: 62rem;
  $w3: 100rem;

  & {
    font-size: $fs1;
    @media screen and (min-width: $w1) {
      font-size: calc(
        #{$fs1} + #{strip-unit($fs2 - $fs1)} *
          ((100vw - #{$w1}) / #{strip-unit($w2 - $w1)})
      );
    }
    @media screen and (min-width: $w2) {
      font-size: calc(
        #{$fs2} + #{strip-unit($fs3 - $fs2)} *
          ((100vw - #{$w2}) / #{strip-unit($w3 - $w2)})
      );
    }
    @media screen and (min-width: $w3) {
      font-size: $fs3;
    }
  }
}
